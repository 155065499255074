import React from "react";
import PageWrapper from "../components/PageWrapper";
import Blog from "../sections/landing/Blogs";
import { Helmet } from "react-helmet";

const BlogPage = () => {
  return (
    <>
    <Helmet>
        <title>Blog - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Tulisan dari kontributor Ethical Hacker Indonesia." />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/blog/" />
        <meta property="og:title" content="Blog" />
        <meta property="og:description" content="Tulisan dari kontributor Ethical Hacker Indonesia." />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Blog"  />
        <meta name="twitter:description" content="Tulisan dari kontributor Ethical Hacker Indonesia." />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
        <Blog />
      </PageWrapper>
    </>
  );
};
export default BlogPage;
